import React from "react"
import styled from "styled-components"

import GlobalStateProvider from "../context/provider"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentWrapper from "../styles/contentWrapper"

const NotFoundPage = () => {
  const globalState = {
    isIntroDone: true,
    darkMode: false,
  }
  const [lang, setLang] = React.useState('tw')

  React.useEffect(() => {
    const path = window.location.pathname;
    const pathSegments = path.split('/').filter(segment => segment !== '');
    const isEn = pathSegments[0]?.toLowerCase() === 'en';

    setLang(isEn ? 'en' : 'tw');
  }, [])


  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout>
        <SEO
          title="404: Not found"
          meta={[{ name: "robots", content: "noindex" }]}
        />
        <div style={{ height: 'calc(70vh)', marginTop: -25, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <h1 data-testid="heading">{lang === 'tw' ? '糟了 ...' : 'NOT FOUND'}</h1>

          <img src='/images/404.gif' width='200' height='200' />
          <p>{lang == 'tw' ? '一望無際的荒原' : 'Vast Wasteland'}</p>

          <a href={lang === 'tw' ? '/' : '/en'} style={{ marginTop: 30, marginBottom: 30, padding: '5px 10px', borderRadius: 10, backgroundColor: '#504B38', color: '#F8F3D9', fontWeight: 500, }} >{lang === 'tw' ? '回首頁' : 'Back To Home'}</a>
        </div>
      </Layout>
    </GlobalStateProvider>
  )
}

export default NotFoundPage
